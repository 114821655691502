const fc = require('festivos-colombia');
 
export function validateHolidays(date) {
    var currentTime = new Date();
    var year = currentTime.getFullYear()
    var cont = 0;
    let holidays = holidays = fc.getHolidaysByYear(year);
    holidays.forEach(element => {
        if(element.date == date){
            cont++;
        }
    });
    if(cont >= 1){
        return true;
    }
    return false;
}